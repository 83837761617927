export const SPECS = {
  CartTooltipWithoutNumber: 'specs.stores.CartTooltipWithoutNumber',
  CartOOIThunderboltCSSOptimization: 'specs.stores.CartOOIThunderboltCSSOptimization',
  ShouldUseOpenSideCartApiInAddToCart: 'specs.stores.ShouldUseOpenSideCartApiInAddToCart',
  ShouldUseEcomPlatformPublicApiAddToCartInPP: 'specs.stores.ShouldUseEcomPlatformPublicApiAddToCartInPP',
  AddSidePaddingOnCartPageForDesktopViewer: 'specs.stores.AddSidePaddingOnCartPageForDesktopViewer',
  AddSlotToCartPage: 'specs.stores.AddSlotToCartPage',
  ExpressCheckoutInSideCart: 'specs.stores.ExpressCheckoutInSideCart',
  ShowCssPerBreakpointIndicationsSideCart: 'specs.stores.ShowCssPerBreakpointIndicationsSideCart',
  UseAllStylesInCart: 'specs.stores.UseAllStylesInCart',
  SideCartElementsVisibilityInCss: 'specs.stores.SideCartElementsVisibilityInCss',
  CheckoutFromSDKInExpressCheckoutButtons: 'specs.stores.CheckoutFromSDKInExpressCheckoutButtons',
  CartFromSDKWhenShowShippingOrShowTax: 'specs.stores.CartFromSDKWhenShowShippingOrShowTax',
  CallEstimateTotalsWithShippingOnNavigate: 'specs.stores.CallEstimateTotalsWithShippingOnNavigate',
  UseCurrentCartFromSdk: 'specs.stores.UseCurrentCartFromSdk',
  WaitForPaymentMethodInit: 'specs.stores.WaitForPaymentMethodInit',
  ShouldNotUseDestinationCompleteness: 'specs.stores.ShouldNotUseDestinationCompleteness',
  MapCartSummaryColors: 'specs.stores.MapCartSummaryColors',
  CheckoutButtonLinkedToSecureCheckout: 'specs.stores.CheckoutButtonLinkedToSecureCheckout',
  FixCallEstimateOnNavigateCondition: 'specs.stores.FixCallEstimateOnNavigateCondition',
  StopSendingLostBusinessEmail: 'specs.stores.StopSendingLostBusinessEmail',
  FixTaxNotShownOnCart: 'specs.stores.FixTaxNotShownOnCart',
  CatchErrorsForActionsOnCart: 'specs.stores.CatchErrorsForActionsOnCart',
  FixLoadingCartInEditorAndPreview: 'specs.stores.FixLoadingCartInEditorAndPreview',
} as const;
